import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages({
  'header.links.courses': {
    id: 'header.links.courses',
    defaultMessage: '课程',
    description: 'Link to the learner course dashboard',
  },
  'header.links.programs': {
    id: 'header.links.programs',
    defaultMessage: '方案',
    description: 'Link to the learner program dashboard',
  },
  'header.links.lives': {
    id: 'header.links.lives',
    defaultMessage: '直播',
    description: 'Link to the learner live dashboard',
  },
  'header.links.content.search': {
    id: 'header.links.content.search',
    defaultMessage: '搜索',
    description: 'Link to the content search page',
  },
  'header.links.schools': {
    id: 'header.links.schools',
    defaultMessage: 'Schools & Partners',
    description: 'Link to the schools and partners landing page',
  },
  'header.user.menu.dashboard': {
    id: 'header.user.menu.dashboard',
    defaultMessage: '课程面板',
    description: 'Link to the user dashboard',
  },
  'header.user.menu.profile': {
    id: 'header.user.menu.profile',
    defaultMessage: '用户资料',
    description: 'Link to the user profile',
  },
  'header.user.menu.account.settings': {
    id: 'header.user.menu.account.settings',
    defaultMessage: '账户',
    description: 'Link to account settings',
  },
  'header.user.menu.order.history': {
    id: 'header.user.menu.order.history',
    defaultMessage: 'Order History',
    description: 'Link to order history',
  },
  'header.user.menu.logout': {
    id: 'header.user.menu.logout',
    defaultMessage: '退出',
    description: 'Logout link',
  },
  'header.user.menu.login': {
    id: 'header.user.menu.login',
    defaultMessage: '登录',
    description: 'Login link',
  },
  'header.user.menu.register': {
    id: 'header.user.menu.register',
    defaultMessage: '注册',
    description: 'Link to registration',
  },
  'header.label.account.nav': {
    id: 'header.label.account.nav',
    defaultMessage: 'Account',
    description: 'The aria label for the account menu nav',
  },
  'header.label.account.menu': {
    id: 'header.label.account.menu',
    defaultMessage: '账号菜单',
    description: 'The aria label for the account menu trigger',
  },
  'header.label.account.menu.for': {
    id: 'header.label.account.menu',
    defaultMessage: '{username} 账号菜单',
    description: 'The aria label for the account menu trigger when the username is displayed in it',
  },
  'header.label.main.nav': {
    id: 'header.label.main.nav',
    defaultMessage: 'Main',
    description: 'The aria label for the main menu nav',
  },
  'header.label.main.menu': {
    id: 'header.label.main.menu',
    defaultMessage: 'Main Menu',
    description: 'The aria label for the main menu trigger',
  },
  'header.label.main.header': {
    id: 'header.label.main.header',
    defaultMessage: 'Main',
    description: 'The aria label for the main header',
  },
  'header.label.secondary.nav': {
    id: 'header.label.secondary.nav',
    defaultMessage: 'Secondary',
    description: 'The aria label for the seconary nav',
  },
});

export default messages;
